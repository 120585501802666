import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import SEO from "../components/seo";

/* HelpOut Page
============================================================================= */

const HelpOutPage = () => {
	// Config

	// Render
	return (
		<>
			<SEO title="Help Out" />
			<div className="w-full">
				<div className="w-full flex lg:flex-row flex-col">
					<div className="lg:w-1/2 w-full pl-10 xl:pr-72 pr-12 lg:pt-24 pt-10 pb-4 flex flex-col">
						<h2 className="text-darkblue text-5xl font-sans font-black pb-6 ibm-plex">
							WANT TO GET INVOLVED?
						</h2>
						<p className="text-darkblue text-base font-sans pb-6 ibm-plex block-content">
							We’re always on the hunt for new talent, partners
							and support. More information about who Isolalid are
							looking for and ways that people can be involved.{" "}
							<br />
							<br />* denotes a required field.
						</p>
					</div>
					<div className="lg:w-1/2 w-full">
						<div className="w-full h-full bg-orange py-24 flex flex-col items-center justify-start">
							<form
								name="Help Out"
								method="POST"
								action="/"
								data-netlify="true"
								className="w-full px-20"
							>
								<input
									type="hidden"
									name="form-name"
									value="Help Out"
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="NAME"
									placeholder="NAME*"
									required
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="EMAIL"
									placeholder="EMAIL*"
									required
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="ARTIST NAME"
									placeholder="ARTIST NAME*"
									required
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="WEBSITE"
									placeholder="YOUR WEBSITE (IF APPLICABLE)"
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="FACEBOOK"
									placeholder="YOUR FACEBOOK URL (IF APPLICABLE)"
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="INSTAGRAM"
									placeholder="YOUR INSTAGRAM URL*"
									required
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="VIDEO"
									placeholder="A VIDEO LINK TO YOUR MUSIC (IF APPLICABLE)"
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="AUDIO"
									placeholder="A URL TO THE AUDIO OF YOUR MUSIC"
									required
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="LOCATION"
									placeholder="WHERE ARE YOU BASED*"
									required
								/>
								<input
									type="text"
									className="mb-8 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									name="TIMEZONE"
									placeholder="WHAT TIMEZONE WILL YOU BE IN FOR YOUR LIVE STREAM?*"
									required
								/>
								<textarea
									className="mb-8 h-32 w-full bg-transparent border-white border-2 ibm-plex px-3 py-2 placeholder-white text-white h-12 lg:text-base text-sm"
									type="text"
									placeholder="MESSAGE*"
									name="MESSAGE"
								/>
								<div className="w-full flex items-center justify-end">
									<input
										className="cursor-pointer bg-beige border-0 w-64 font-black ibm-plex px-3 py-2  text-darkblue h-12 lg:text-base text-sm"
										type="submit"
										value="SEND"
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

/* Export
============================================================================= */

export default HelpOutPage;
